<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="学期" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入学期" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1 flex align-center">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<el-button type="primary" icon="el-icon-download" size="mini" @click="downloadTemplate">下载模板</el-button>
				<div class="ml-auto">
					<el-tooltip class="item" effect="dark" content="刷新" placement="top">
					  <el-button size="mini" circle icon="el-icon-refresh" @click="getList" />
					</el-tooltip>
				</div>
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="学期" align="center" min-width="100">
				  <template slot-scope="scope">
					<span>{{ scope.row.year +' '+ scope.row.tag}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="考核人数"  align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nums }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="完成状态"  align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.is_finish === 1" size="mini" type="success">已完成</el-tag>
					<el-tag v-if="scope.row.is_finish === 2" size="mini" type="danger">未完成</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="状态" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="170" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					  <el-button type="primary" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="导入" placement="top" v-if="scope.row.is_finish == 2">
					  <el-upload
					    class="upload-demo d-inline-block mx-1"
					    ref="upload"
					    action="#"
						:on-change="(file, fileList) => {importExcel(file, scope.row.id)}"
					    :on-success="importSuccess"
					    :on-error="importError"
					    :before-upload="beforeImportUpload"
					    :limit="1"
					    :multiple="false"
					    :show-file-list="false"
					    accept=".xls, .xlsx"
					    :auto-upload="false"
					    >
					    <el-button slot="trigger" icon="el-icon-upload2" size="mini" type="warning" circle></el-button>
					  </el-upload>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="导出" placement="top">
					  <el-button type="info" icon="el-icon-download" size="mini" circle @click="downloadExcel(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="scope.row.is_finish == 2">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="scope.row.is_finish == 2">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="完成" placement="top" v-if="scope.row.is_finish != 1  && queryForm.role_id == 1">
					  <el-button type="warning" icon="el-icon-circle-check" size="mini" circle @click="handleFinish(scope.row.id)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="撤销" placement="top" v-if="scope.row.is_finish == 1 && queryForm.role_id == 1">
					  <el-button type="warning" icon="el-icon-refresh" size="mini" circle @click="handleRefresh(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="650px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small">
			<el-form-item label="学期" prop="s_id">
			  <el-select v-model="form.s_id" placeholder="请选择学期" style="width: 100%;">
			    <el-option :value="item.id" :label="item.year+' '+item.tag" v-for="(item,index) in semester" :key="index"/>
			  </el-select>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import dayjs from 'dayjs'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'dwjk',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					role_id:this.VueCookies.get('role_id'),
				},
				form:{
					s_id:'',
				},
				rules:{
					s_id: [
					  { required: true, message: '请选择学期', trigger: ['blur', 'change'] }
					],
				},
			}
		},
		mounted() {
			this.getsemester()
		},
		methods:{
			reset() {
			  this.form = {
			    s_id:'',
			  }
			  this.resetForm('form')
			},
			handleView(row){
				this.$router.push({
				  path:"/dwjk_view",
				  query: {
					id: row.id,
					s_id:row.s_id
				  }
				})
			},
			downloadTemplate(){
				this.axios.get('/manage/dwjk/downloadTemplate',{
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '对外监考导入模板.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e,id){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				formdata.append('id', id)
				this.axios.post('/manage/dwjk/importExport',formdata).then(res=>{
					console.log(res)
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					this.$refs.upload.clearFiles()
				})
			},
			downloadExcel(row){
				this.axios.get('/manage/dwjk/downloadExcel',{
					responseType: 'arraybuffer',
					params:{
						id: row.id,
					}
				}).then(res=>{
					console.log(res)
					  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '对外监考_'+dayjsTime+'.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
		}
	}
</script>

<style>
</style>